import React from 'react'
import { Link } from "react-router-dom";

export default function IndexTr() {
    return (
        <div className="container pt-5">

            <div className="frame row color_white">
                <div className="text_area p-3" >
                    <div className="row">
                        <div className="col-md-3"><Link to="/en"><img src="/assets/en.png" /></Link></div>
                        <div className="col-md-6 text-center ">
                            <h1 style={{ fontWeight:'bold'}}><span style={{ color:'red' }}>(</span>re<span style={{ color:'red' }}>)</span>PAZARCIK</h1>
                            <br />
                            www.repazarcik.org<br />
                            <br />
                            <h4>Yakında Buradayız !</h4>
                            <br />
                            Revive Pazarcik Foundation

                            <br />
                            <br />

                        </div>
                        <div className="text-right col-md-3" style={{ textAlign:'right' }}></div>
                    </div>
                    <div className="col-md-12" style={{ fontSize:'16px' }}>
                        Sayın Arkadaşlar ve Destekçiler,<br />
                        <br />
                        Pazarcık'ımızda geçtiğimiz zamanlarda meydana gelen depremler geniş çaplı yıkım ve
                        kayıplara neden oldu. Etkilenen tüm insanlara içimiz ağrıyor ve yardım ve desteği
                        sunmak için elinden geleni yapıyoruz.<br />
                        <br />
                        Büyük Pazarcık halkı, şehir, köy ve kasabalarımızı canlandırmak ve toplumumuzu
                        yeniden inşa etmek için bir araya geliyor. Acil durumlarımızı çözüyor ve depremlerin
                        etkilerini azaltmak için kısa ve uzun dönemli planlar oluşturuyoruz.<br />
                        <br />
                        Memleketimiz ve insanlarımız hakkında gurur duyuyoruz ve bu zor zamanlarda
                        toplumumuza destek olmak için elinden geleni yapmaya kararlıyız. Bunu tek başımıza
                        yapamayacağımızı ve arkadaşlarımızın, komşularımızın ve dünya çapında daha geniş
                        toplumun desteğine ve yardımına ihtiyacımız olduğunu anlıyoruz.<br />
                        <br />
                        Bu nedenle, toplumumuza destek veren ve bize ilerlemeyi sağlamak için bilgi ve
                        kaynaklar sunan bir platform olarak (Re) Pazarcik Vakfı'na ait yeni bir web sitesi
                        lansmanı yapıyoruz. Bu web sitesi, yardım çalışmalarıyla ilgili tüm şeylerin merkezi bir
                        noktası olacak ve bilgi, güncellemeler ve katılım fırsatları için bir merkez sağlayacaktır.
                        Birlikte, güçlüyüz. Pazarcık'ı canlandırma çalışmalarına katılmak ve bizimle birlikte
                        durmak isteyeceğinizi umuyoruz. Lütfen toplumumuza nasıl destek olabileceğiniz
                        hakkında daha fazla bilgi edinmek için web sitemiz <u>çok yakında</u> burada olacak.<br />
                        <br />
                        Birlikte, yeniden doğarız ve daha güçlü bir Pazarcik'ı yeniden inşa ederiz.<br />
                        <br />
                        Saygılarımızla,<br />

                        Pazarcık Halkı<br />
                    </div>
                </div>
            </div>
        </div>
    )
}


